import logo from './logo.svg';
import './App.css';
import { Routes,Route } from 'react-router-dom';
import Homepage from './Pages/Homepage/Homepage';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Form from '../src/Pages/Form/Form';
import Castledetail from './Pages/Castledatail/Castledetail';
function App() {
  return (
    <div className="App">
      <div>
        <Routes>
        <Route path={'/'} element={<Homepage/>}/>
          <Route path={'/Home'} element={<Homepage/>}/>
          <Route path={'/About'} element={<About/>}/>
          <Route path={'/Contact'} element={<Contact/>}/>
          <Route path={'/Form'} element={<Form/>}/>
          <Route path={'/Castledetail/:id'} element={<Castledetail/>}/>
        </Routes>
      </div>
    </div>
  );
}
export default App;

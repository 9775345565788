import React from 'react';
import './style.scss';

const Banner = () => {
  return (
    <div className="banner">
      <img src="https://img.freepik.com/premium-vector/futuristic-light-red-blue-gaming-header-social-media-banner-template_136469-1324.jpg?semt=ais_hybrid" alt="Banner" className="banner__image" />
    </div>
  );
};

export default Banner;

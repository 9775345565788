// src/components/MainCastles/MainCastles.jsx
import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const MainCastles = () => {
  const castles = [
    { id: 6, name: 'Castle F', description: 'A grand medieval castle with towering walls.', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL4bxwOvPMydJJoeOvfvKugHccQ66HKMC4aQ&s" },
    { id: 7, name: 'Castle G', description: 'A picturesque castle overlooking a serene lake.', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjiZQHIHXN735WNMmGP50IJbTLFNSC6lPyfA&s" },
    { id: 8, name: 'Castle H', description: 'A ruined castle steeped in ancient legends.', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWqQSbwe1npBe2u3XrZXMbHnRWYNr7ZF0Sdg&s" },
    { id: 9, name: 'Castle I', description: 'A fortified castle with a rich history of battles.', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYrQz5PV7MzD9kEUyiL-BTe4OLelO1AYD1zg&s" },
    { id: 10, name: 'Castle J', description: 'A majestic castle surrounded by dense forests.', image: "https://www.historyhit.com/app/uploads/2021/10/Stronghold-750px.jpg" },
    { id: 11, name: 'Castle K', description: 'A majestic castle surrounded by dense forests.', image: "https://www.conisbroughcastle.org.uk/images/castle-building-games/stronghold-crusader-2-hd.jpg" },
  ];

  return (
    <section className="main-castles">
      <h2 className="main-castles__heading">Main Castles</h2>
      <div className="main-castles__grid">
        {castles.map(castle => (
          // <Link to={`/Castledetail/${castle.id}`} style={{textDecoration:"none"}}>
          <a href="https://wa.me/918210085118"  style={{textDecoration:"none"}}>
            <div key={castle.id} className="main-castles__item">
            <img src={castle.image} alt={castle.name} className="main-castles__image" />
            <h3>{castle.name}</h3>
            </div>
          </a>
          // </Link>
          
        ))}
      </div>
    </section>
  );
};

export default MainCastles;

import React from 'react'
import Banner from '../../Component/Banner/Banner'
import MainCastle from '../../Component/Maincasltes/Castle'
import AllCastles from '../../Component/AllCastle/Allcastle'
const Homepage = () => {
  return (
    <div>
        <Banner/>
        <MainCastle/>
        <AllCastles title={"All Castles"}/>
    </div>
  )
}

export default Homepage
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import AllCastles from '../../Component/AllCastle/Allcastle';

const Castledetail = () => {
  const { id } = useParams();
  const [castle, setCastle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update window width
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Smooth scroll to top on mount or when id changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [id]);

  useEffect(() => {
    // Fetch castle details based on ID
    const fetchCastleDetails = async () => {
      try {
        const response = await fetch(`https://www.backe.spcref.com/castles/id/${id}`);
        if (!response.ok) throw new Error('Network response not ok');
        const [data] = await response.json(); // Destructure the array to get the single object
        setCastle(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setCastle(null); // Ensures we handle error case in the UI
        setLoading(false);
      }
    };
    fetchCastleDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (!castle) return <p>Castle not found.</p>;

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '20px auto',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      marginTop: '100px',
    },
    videoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
    },
    iframe: {
      width: '100%',
      height: '500px', // Increased height
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontSize: '2.5rem',
      color: '#3a3a3a',
      marginBottom: '20px',
      textAlign: 'center', // Center align title
    },
    description: {
      fontSize: '1.125rem',
      lineHeight: '1.6',
      color: '#555',
      textAlign: 'justify',
      marginBottom: '20px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '1rem',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      marginBottom: '10px', // Add margin for spacing
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    },
    allCastles: {
      marginTop: '30px',
    },
    detailsContainer: {
      marginTop: '30px',
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '1200px',
      margin: '20px auto',
    },
    detailsTitle: {
      fontSize: '2rem',
      color: '#3a3a3a',
      marginBottom: '10px',
    },
    detailsContent: {
      fontSize: '1rem',
      lineHeight: '1.6',
      color: '#555',
    },
    detailsRow: {
      marginBottom: '15px',
      display: 'flex',
      flexDirection: windowWidth <= 768 ? 'column' : 'row',
      justifyContent: 'space-between',
      textAlign: 'justify',
    },
    detailsLabel: {
      fontWeight: 'bold',
      flex: '1',
      marginBottom: windowWidth <= 768 ? '5px' : '0',
    },
    detailsValue: {
      flex: '2',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.videoContainer}>
        <video
          style={styles.iframe}
          src={castle.video} // Ensure this URL is correct and points to a playable video
          title={castle.name}
          controls // Allow user interaction
          autoPlay // Optional: Automatically play the video
          muted // Optional: Mute the video if autoplay is enabled
        >
          Your browser does not support the video tag.
        </video>
      </div>

      <div style={styles.content}>
        <h1 style={styles.title}>{castle.name}</h1>
        <p style={styles.description}>{castle.description}</p>
        <Link to={'/Contact'}>
          <button style={styles.button}>Contact Us</button>
        </Link>
      </div>

      {/* New section for additional details */}
      <div style={styles.detailsContainer}>
        <h2 style={styles.detailsTitle}>Additional Details</h2>
        <div style={styles.detailsContent}>
          {Object.entries(castle).filter(([key]) => !['image', 'video', 'name', 'description', 'createdAt', 'updatedAt', '_id'].includes(key)).map(([key, value]) => (
            <div style={styles.detailsRow} key={key}>
              <span style={styles.detailsLabel}>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}:</span>
              <span style={styles.detailsValue}>
                {Array.isArray(value) ? value.join(', ') : typeof value === 'object' ? JSON.stringify(value) : value}
              </span>
            </div>
          ))}
        </div>
      </div>

      <AllCastles title={"Similar Castles"} style={styles.allCastles} />
    </div>
  );
};

export default Castledetail;

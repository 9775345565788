import React, { useEffect, useState } from 'react';
import './Style.scss';
import { Link } from "react-router-dom";

const AllCastles = ({ title }) => {
  const [castles, setCastles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://www.backe.spcref.com/castles/all");
        if (!response.ok) {
          throw new Error('Network response not ok');
        }
        const data = await response.json();
        // Assuming the API response is an array of castle objects
        setCastles(data);
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="all-castles">
      <h2 className="all-castles__heading">{title}</h2>
      <div className="all-castles__list">
        {castles.map(castle => (
          <Link to={`/Castledetail/${castle._id}`} key={castle._id} style={{ textDecoration: "none" }}>
            <div className="all-castles__item">
              <img src={castle.image} alt={castle.name} className="all-castles__image" />
              <h3>{castle.name}</h3>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default AllCastles;

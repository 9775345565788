import React, { useState } from 'react';
import axios from 'axios';
import './Style.scss';

const InfoPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    image: '',
    video: '',
    email: '',
    state: '',
    migrationBracket: '',
    troopsPower: '',
    technologyPower: '',
    unitSpecialtyPower: '',
    dragonLevel: '',
    dragonPower: '',
    paidHeroes: 'No',
    rocSpec: '',
    loftySpec: '',
    dmSets: '',
    legionSkins: '',
    pendants: '',
    castleSkins: '',
    wings: '',
    halo: '',
    migrationTickets: '',
    decorScore: '',
    specialBagItems: ['', '', ''], // 3 items in specialBagItems
    desiredPrice: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle specialBagItems separately
  const handleSpecialBagItemChange = (index, value) => {
    const newItems = [...formData.specialBagItems];
    newItems[index] = value;
    setFormData({
      ...formData,
      specialBagItems: newItems,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://www.backe.spcref.com/castle-forms/create', formData);
      console.log('Form Data Submitted:', response.data);
      setSuccessMessage('Form submitted successfully!');
      setErrorMessage('');
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('There was an error submitting the form. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="info-page">
      <h1>Information Form</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Castle Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Castle Image URL:</label>
          <input type="text" name="image" value={formData.image} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Video URL:</label>
          <input type="text" name="video" value={formData.video} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>State:</label>
          <input type="text" name="state" value={formData.state} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Migration Bracket:</label>
          <input type="text" name="migrationBracket" value={formData.migrationBracket} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Troops Power:</label>
          <input type="text" name="troopsPower" value={formData.troopsPower} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Technology Power:</label>
          <input type="text" name="technologyPower" value={formData.technologyPower} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Unit Specialty Power:</label>
          <input type="text" name="unitSpecialtyPower" value={formData.unitSpecialtyPower} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Dragon Level:</label>
          <input type="text" name="dragonLevel" value={formData.dragonLevel} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Dragon Power:</label>
          <input type="text" name="dragonPower" value={formData.dragonPower} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Paid Heroes (Y/N):</label>
          <input type="text" name="paidHeroes" value={formData.paidHeroes} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>ROC Spec:</label>
          <input type="text" name="rocSpec" value={formData.rocSpec} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Lofty Spec:</label>
          <input type="text" name="loftySpec" value={formData.loftySpec} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>DM Sets:</label>
          <input type="text" name="dmSets" value={formData.dmSets} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Legion Skins:</label>
          <input type="text" name="legionSkins" value={formData.legionSkins} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Pendants:</label>
          <input type="text" name="pendants" value={formData.pendants} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Castle Skins:</label>
          <input type="text" name="castleSkins" value={formData.castleSkins} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Wings:</label>
          <input type="text" name="wings" value={formData.wings} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Halo:</label>
          <input type="text" name="halo" value={formData.halo} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Migration Tickets:</label>
          <input type="text" name="migrationTickets" value={formData.migrationTickets} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Decor Score:</label>
          <input type="text" name="decorScore" value={formData.decorScore} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Special Bag Items:</label>
          {formData.specialBagItems.map((item, index) => (
            <input
              key={index}
              type="text"
              value={item}
              onChange={(e) => handleSpecialBagItemChange(index, e.target.value)}
              placeholder={`Item ${index + 1}`}
            />
          ))}
        </div>

        <div className="form-group">
          <label>Desired Price:</label>
          <input type="text" name="desiredPrice" value={formData.desiredPrice} onChange={handleChange} />
        </div>

        <button type="submit" className="submit-btn">Submit</button>
      </form>

      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default InfoPage;

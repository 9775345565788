import React, { useState } from 'react';
import './Navbar.scss';
import { Link } from 'react-router-dom';
import applyform from '../../Pages/Form/Form'
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar__menu-icon" onClick={toggleMenu}>
        &#9776;
      </div>

      <ul className={`navbar__list ${isMenuOpen ? 'navbar__list--open' : ''}`}>
        <li className="navbar__item">
          <Link to="/Home" onClick={closeMenu}>Home</Link>
        </li>
        <li className="navbar__item">
          <Link to="/About" onClick={closeMenu}>About Us</Link>
        </li>
        <li className="navbar__item">
          <Link to="/Contact" onClick={closeMenu}>Contact</Link>
        </li>
        <li className="navbar__item">
          <Link to="/Form" onClick={closeMenu}>Form</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;

import React from 'react';
import './Style.scss';

const About = () => {
  return (
    <div className="about-container">
      <section className="about-header">
        <h1>About Our Castle Emporium</h1>
        <p>Welcome to the ultimate destination for castle enthusiasts and investors. At our Castle Emporium, we specialize in curating and offering the most majestic castles from around the world.</p>
      </section>

      <section className="about-content">
        <div className="about-text">
          <h2>Who We Are</h2>
          <p>
            Our team is a group of passionate historians, architects, and real estate experts with a deep love for history and heritage. We bring together centuries-old castles with modern buyers who are looking for a piece of history to call their own.
            <br /><br />
            Whether you're seeking a grand medieval fortress, a quaint castle in the countryside, or a luxurious chateau with breathtaking views, our mission is to match you with the perfect property that meets your dreams and desires.
          </p>
        </div>
        <div className="about-image">
          <img src="https://cdn.pixabay.com/photo/2024/02/25/03/50/castle-8595167_640.jpg" alt="Our Team" />
        </div>
      </section>

      <section className="about-services">
  <div className="services-content">
    <h2>Our Exclusive Services</h2>
    <ul>
      <li>
        <h3>Castle Acquisition and Sales</h3>
        <p>We specialize in helping you acquire historic castles with ease. Our extensive network ensures that we find the most suitable properties to meet your needs.</p>
        <p>Whether you’re looking to buy a medieval fortress or a charming castle, our team guides you through every step of the purchase process.</p>
      </li>
      <li>
        <h3>Restoration and Renovation Advisory</h3>
        <p>Our experts offer detailed advice on restoring and renovating historic properties while preserving their unique charm.</p>
        <p>From structural assessments to design recommendations, we help you transform your castle into a modern masterpiece without compromising its heritage.</p>
      </li>
      <li>
        <h3>Historical Research and Valuation</h3>
        <p>We provide comprehensive historical research and accurate valuations for castles, ensuring you understand their historical significance and market value.</p>
        <p>Our reports are detailed and informative, giving you insights into the castle’s past and its potential as a valuable investment.</p>
      </li>
      <li>
        <h3>Luxury Property Management</h3>
        <p>Our property management services cater to the unique needs of luxury castle owners. We handle everything from day-to-day operations to high-end maintenance.</p>
        <p>Experience peace of mind knowing that your property is managed with the utmost care and attention to detail.</p>
      </li>
    </ul>
  </div>
</section>


      <section className="about-gallery">
        <h2>Featured Castles</h2>
        <div className="gallery">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9sfP_K-jh7qyQKBNvq4-xkT347JRlNVN4LA&s" alt="Castle 1" />
          <img src="https://png.pngtree.com/thumb_back/fh260/background/20230712/pngtree-modern-black-joysticks-set-on-abstract-light-wallpaper-perfect-for-gaming-image_3865865.jpg" alt="Castle 2" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWoGAK6Rc22_jIjTfFOKfRZQ7ve9dWfxBOog&s" alt="Castle 3" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9sfP_K-jh7qyQKBNvq4-xkT347JRlNVN4LA&s" alt="Castle 1" />
          <img src="https://png.pngtree.com/thumb_back/fh260/background/20230712/pngtree-modern-black-joysticks-set-on-abstract-light-wallpaper-perfect-for-gaming-image_3865865.jpg" alt="Castle 2" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWoGAK6Rc22_jIjTfFOKfRZQ7ve9dWfxBOog&s" alt="Castle 3" />
        </div>
      </section>

      <section className="about-contact">
        <h2>Connect with Us</h2>
        <p>Are you ready to explore the world of castles or have any inquiries about our listings? Our team is here to assist you every step of the way.</p>
        <button className="contact-button">Contact Us</button>
      </section>
    </div>
  );
};

export default About;
